var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      staticClass: "table-inner-button",
      attrs: { type: "primary", size: "medium", loading: this.loading },
      on: {
        click: function($event) {
          return _vm.sendSyncMessage()
        }
      }
    },
    [_vm._v(_vm._s(_vm.buttonInfo.buttonName))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <el-button type="primary" size="medium" :loading="this.loading" @click="sendSyncMessage()" class="table-inner-button">{{ buttonInfo.buttonName }}</el-button>
</template>
<script>

export default {
    name: 'sendSyncMessageButton',
    props: {
        buttonInfo: {
            buttonName: {
                type: String
            },
            label: {
                type: String
            },
            lockTime: {
                type: Number,
                default: 5
            }
        }
    },
    data() {
        return {
            loading: false
        };
    },

    created() {

    },
    methods: {

        sendSyncMessage() {

            var params = {};
            params.label = this.buttonInfo.label;
            this.loading = true;
            this.$store.dispatch('getLeftLockTime', params).then((res) => {

                if (res > 0) {

                    this.$message({
                        message: '锁定中，剩余时间' + res + "分钟",
                        type: 'info'
                    });
                    this.loading = false;
                }else {

                    params.lockTime = this.buttonInfo.lockTime;
                    this.$store.dispatch('sendFullSyncMsg', params).then((res) => {
                        this.$message({
                            message: '发送成功',
                            type: 'success'
                        });
                    });
                    this.loading = false;
                }
            });

        }

    }
};
</script>

<template>
    <div>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>同步按钮</span>
            </div>

            <el-row>
                <sendSyncMessageButton v-for="(item, index) in buttonInfos" :buttonInfo='item'></sendSyncMessageButton>
            </el-row>

        </el-card>


 
    </div>
</template>
<script>

import sendSyncMessageButton from '@/views/sync/sendSyncMessageButton.vue';
import Config from '@/utils/config';
import Cache from '@/utils/cache';

export default {
    components: { sendSyncMessageButton },
    name: 'fullsync',
    data() {
        return {
            fulltag: '',
            buttonInfos: [
                {buttonName: '删除产品文件同步', label: 'java.arc.center.subject.goods.file.relation.all', lockTime: 15},
                {buttonName: '删除产品图片同步', label: 'java.arc.center.subject.goods.image.relation.all', lockTime: 30},
                {buttonName: '删除产品参数同步', label: 'java.arc.center.subject.goods.parameter.relation.all', lockTime: 30},
                {buttonName: '删除类目同步', label: 'java.arc.center.subject.goods.classify.del.all', lockTime: 30},
                {buttonName: '删除类目与产品关系同步', label: 'java.arc.center.subject.goods.classify.relation.del.all', lockTime: 30},
                {buttonName: '删除类目关联参数同步', label: 'java.arc.center.subject.goods.classify.parameter.del.all', lockTime: 30},
            ],
            hideButtonInfos: [

            ],
            userId: Cache.get(Config.userInfo).org_user_id,
 

        };
    },
    watch: {},
    created() {

    },
    mounted() {
    },
    methods: {
    }
};


</script>


<style scoped>


/*.box-card {*/
/*    width: 800px;*/
/*}*/

</style>